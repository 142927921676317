<template>
    <div>
      <a href="https://www.linkedin.com/in/andrei-ardelean13/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="https://www.facebook.com/ardelean.andrei13/" target="_blank">
        <i class="fab fa-facebook"></i>
      </a>
      <a href="https://www.instagram.com/andiiquetheslayer/" target="_blank">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://github.com/andiique" target="_blank">
        <i class="fab fa-github"></i>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    
  }
  </script>
  
  <style scoped>
  i.fab {
    font-size: 24px; 
    color: #fff;
  }
  
  /* Style the round buttons */
  a {
    width: 40px;
    height: 40px;
    background-color: #0b1c98;
    border: none;
    border-radius: 50%; /* Make the button round */
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* Add spacing between buttons */
    text-decoration: none; /* Remove underlines from links */
  }


  @media (min-width: 320px) {

  i.fab {
    font-size: 5vw; 
    color: #fff;
  }
  
  /* Style the round buttons */
  a {
    width: 9vw;
    height: 9vw;
    background-color: #0b1c98;
    border: none;
    border-radius: 50%; /* Make the button round */
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* Add spacing between buttons */
    text-decoration: none; /* Remove underlines from links */
  }
  }

@media (min-width: 1280px) { 

i.fab {
    font-size: 24px; 
    color: #fff;
  }
  
  /* Style the round buttons */
  a {
    width: 40px;
    height: 40px;
    background-color: #0b1c98;
    border: none;
    border-radius: 50%; /* Make the button round */
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* Add spacing between buttons */
    text-decoration: none; /* Remove underlines from links */
  }

}

  </style>
  