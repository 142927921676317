<template>
	<p>
		v0.2.1 - alpha | Handcrafted in Valencia, ES. | © 2023 by Andrei Ardelean. All rights reserved.
	</p>
</template>

<style scoped>

p {
	font-size: 1rem;
	color: rgba(255, 255, 255, 0.3);
	font-weight: 400;
	text-align: center;
	line-height: 1.2;
	margin-bottom: 0;
	margin-top: -20px;
}

@media (min-width: 320px) {

p {
	font-size: 3vw;
	color: rgba(255, 255, 255, 0.3);
	font-weight: 400;
	text-align: center;
	line-height: 1.2;
	margin-bottom: 0;
	margin-top: -5vw;
}
}

@media (min-width: 1280px) {

p {
	font-size: 1rem;
	color: rgba(255, 255, 255, 0.3);
	font-weight: 400;
	text-align: center;
	line-height: 1.2;
	margin-bottom: 0;
	margin-top: -20px;
}

 }

</style>